<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="我的资料"
      left-text="返回"
      :right-text="!state.loading ? '更新' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="
        !state.loading && $router.push({ name: 'AgentProfileUpdate' })
      "
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-cell title="代理商名称" :value="state.agent_name" />
      <van-cell title="代理商等级" :value="state.agent_level" />
      <van-cell title="联系人名称" :value="state.contact_name" />
      <van-cell title="联系人手机" :value="state.contact_phone" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, reactive } from "vue";
import { Field, Picker, Toast } from "vant";
import { VERIFY_AGENT } from "@/store/modules/auth";
export default {
  name: "StationDetail",
  components: {
    [Field.name]: Field,
    [Picker.name]: Picker,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      agent_name: "",
      agent_level: "",
      contact_name: "",
      contact_phone: "",
      loading: true,
    });

    const loadAgentInfo = async () => {
      store
        .dispatch(VERIFY_AGENT)
        .then(({ result }) => {
          state.agent_name = result.agent_name;
          state.contact_name = result.contact_name;
          state.contact_phone = result.contact_phone;
          state.agent_level = result.level == 1 ? "一级代理" : "二级代理";
          state.loading = false;
        })
        .catch((error) => {
          state.loading = false;
          const { data, statusText } = error;
          const msg = data && data.msg ? data.msg : statusText;
          Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
          if (!msg) console.log(error);
        });
    };

    onMounted(() => {
      loadAgentInfo();
    });

    return { state };
  },
};
</script>
